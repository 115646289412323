import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import CsvDialog from "../../Utils/CsvDialog";
import { history } from "../../Helpers/history";
import Row from "react-bootstrap/Row";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import { useAddStockAdjustment } from "./hooks/useAddStockAdjustment";
import {
  CollapsibleList,
  DataCell,
  ModuleTable,
} from "../../Utils/List/CollapsibleList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import { AutoCompleteUnControlled } from "../../Utils/FormInputs/AutoCompleteInput";
import styled from "@emotion/styled/macro";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import TableCell from "@material-ui/core/TableCell";
import { LoadingGif } from "../../Utils/Loader";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import Textarea from "../../Utils/FormInputs/Textarea";
import CustomDialog from "../../Utils/Dialog/CustomDialog";
import { BlockNavigationComponent } from "../../Utils/ReusableComponents/BlockNavigationComponent";
import { dateStringConvert } from "../../Utils/ConvertDate";

const headData = [
  { item: "Batch#", align: "left", isRequired: true },
  { item: "Expiry Date", align: "center" },
  { item: "Stock", align: "right" },
  { item: "Physical Stock", align: "center" },
  { item: "Adj. Type", align: "center" },
  { item: "Reason", align: "center" },
  { item: "Unit Cost", align: "center" },
  { item: "Action", align: "right" },
];

const Container = styled.div({
  padding: "15px 120px 15px 70px",
});

export const StyledRemoveIcon = styled(RemoveCircleIcon)({
  width: "0.9em",
  height: "0.9em",
  fill: "#c4495c",
});

const StyledInput = styled(TextField)(
  {
    display: "inline",
    borderRadius: "0.7rem",
    fontSize: "13px",
  },
  ({ width }) => ({ width: width })
);

const StyledSelect = styled(SelectInput)(
  {
    display: "inline",
    borderRadius: "0.7rem",
    fontSize: "13px",
  },
  ({ width }) => ({ width: width })
);

const StockAdjustment = ({ actions, snackbars, match: { params } }) => {
  const {
    products,
    product,
    drugs,
    handleOpen,
    handleClickDrug,
    handleRetrieveDrugs,
    purchaseDate,
    isPending,
    isResolved,
    submitted,
    handleRemoveBatch,
    retrieveExpiry,
    handleRemoveProduct,
    handleChangeItem,
    handleChangePurchaseCsv,
    handleChangeDate,
    handleOpenDialog,
    handleAddBatch,
    fetchData,
    openDialog,
    handleCloseBar,
    handleCloseDialog,
    handleSubmit,
    handleUploadCsv,
    isLoading,
    isSuccess,
    isError,
    state,
    handleChange,
    openReport,
    handleViewReport,
    handleCloseReport,
    handleCancelView,
    isBlocking,
  } = useAddStockAdjustment(actions, params);

  const allDrugs = !drugs ? [] : drugs;
  const { open, message, variant } = snackbars;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const singleUser = !user ? {} : user;
  const user_name = !singleUser.user_name ? "" : singleUser.user_name;
  const productLength = products.some((item) => item.batches.length > 0);
  const { description } = state;

  const buttons = (
    <>
      <button
        onClick={handleViewReport}
        className="btn btn-sm pharmacy-info-btn mr-3"
      >
        View
      </button>
      <button
        onClick={handleCancelView}
        className="btn btn-sm pharmacy-gray-btn mr-3"
      >
        No
      </button>
    </>
  );

  return (
    <div>
      <ReusableDashboardHeader
        component="Stock Adjustment"
        heading="Stock"
        subHeading="Stock Adjustment"
        link={history.location.pathname}
      />
      <CsvDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        stock
        handleUploadCsv={handleUploadCsv}
        handleChangeUpload={handleChangePurchaseCsv}
        message="Stock"
      >
        <span>
          <button
            onClick={fetchData}
            className="btn pharmacy-primary-btn btn-sm"
          >
            Download Sample File
          </button>
        </span>
      </CsvDialog>

      <CustomDialog
        buttons={buttons}
        open={openReport}
        handleClose={handleCloseReport}
        title="View Report"
        maxWidth="xs"
      >
        <p style={{ margin: 0 }}>Would you like to view the report</p>
      </CustomDialog>
      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseBar}
      />
      <BlockNavigationComponent
        when={isBlocking}
        shouldBlockNavigation={() => {
          return isBlocking;
        }}
        navigate={(path) => history.push(path)}
      />
      <Container>
        <div className="text-right">
          <button
            className="btn pharmacy-info-btn mx-3 btn-sm"
            onClick={handleOpenDialog}
          >
            Upload Stock CSV
          </button>
        </div>
        <form
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <div className="row">
            <div className="col-md-6">
              <Form.Group as={Row}>
                <Label column sm="3" name="Date" htmlFor="date" />
                <Col sm="8">
                  <TextField
                    name="purchase_date"
                    value={purchaseDate}
                    type="date"
                    id="date"
                    onChange={handleChangeDate}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Label column sm="3" name=" Adjusted by" htmlFor="adjustedBy" />
                <Col sm="8">
                  <TextField
                    value={user_name}
                    type="text"
                    readOnly
                    id="adjustedBy"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Label type name="Product" column sm="3" htmlFor="product" />
                <Col sm="8">
                  <AutoCompleteUnControlled
                    options={allDrugs}
                    handleChange={handleRetrieveDrugs}
                    label_text="label"
                    medicine_value={{
                      label: product.product_name,
                      value: product.product_id,
                    }}
                    id="product_name"
                    data-testid="product"
                    value_text="value"
                    handleProductData={handleClickDrug}
                    placeholder="Type Product Name"
                    errorMsg="Enter Product Name"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Label column sm="3" name="Description" htmlFor="description" />
                <Col sm="8">
                  <Textarea
                    value={description}
                    type="text"
                    name="description"
                    id="description"
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </div>
            <div className="col-md-6" />
          </div>
          {isLoading ? (
            <div className="text-center">
              <LoadingGif />
            </div>
          ) : null}
          {isSuccess & !isResolved ? (
            productLength & !isResolved ? (
              products.map((item, index) => {
                const batches = item.batches ? item.batches : [];
                console.log("product", item);
                return batches.length > 0 ? (
                  <CollapsibleList
                    key={index}
                    open={item.open}
                    name={item.product_name}
                    handleOpen={() => handleOpen(index)}
                    button={
                      <>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => handleRemoveProduct(index)}
                          >
                            <StyledRemoveIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  >
                    <ModuleTable headData={headData}>
                      {batches.map((row, idx) => {
                        console.log("batches", row);
                        const daysLeft = Math.floor(
                          (Date.parse(row.expiry_date) - Date.now()) /
                            (24 * 60 * 60 * 1000)
                        );
                        console.log(item.expiry_limit, " hello ", daysLeft);

                        return (
                          <TableRow key={idx}>
                            <DataCell align="left">
                              {row.isBatch ? (
                                row.batch_id
                              ) : (
                                <>
                                  <StyledInput
                                    submitted={submitted}
                                    data-testid="batch_id"
                                    onBlur={() =>
                                      retrieveExpiry(
                                        batches,
                                        row.batch_id,
                                        index,
                                        idx
                                      )
                                    }
                                    onChange={(e) =>
                                      handleChangeItem(e, index, idx)
                                    }
                                    value={row.batch_id}
                                    name="batch_id"
                                    type="text"
                                    width="50%"
                                  />
                                  {submitted && !row.batch_id && (
                                    <div className="invalid-text">
                                      Batch id is required
                                    </div>
                                  )}
                                </>
                              )}
                            </DataCell>
                            <DataCell align="center" width="200">
                              {row.isBatch ? (
                                dateStringConvert(row.expiry_date)
                              ) : (
                                <>
                                  <StyledInput
                                    submitted={submitted}
                                    data-testid="expiry_date"
                                    disabled={row.disableExpiry}
                                    onChange={(e) =>
                                      handleChangeItem(e, index, idx)
                                    }
                                    // value={dateStringConvert(row.expiry_date)}
                                    type="date"
                                    name="expiry_date"
                                    width="100%"
                                  />
                                  {/* {console.log(
                                    item.expiry_limit,
                                    " hello ",
                                    daysLeft
                                  )} */}
                                  {submitted && !row.expiry_date ? (
                                    <div className="invalid-text">
                                      Expiry date is required
                                    </div>
                                  ) : item.expiry_limit > daysLeft ? (
                                    <div className="invalid-text">
                                      This batch is within the product's expiry
                                      limit
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </DataCell>
                            <TableCell align="right" data-testid="stock">
                              {row.stock}
                            </TableCell>
                            <DataCell align="center">
                              <StyledInput
                                onChange={(e) =>
                                  handleChangeItem(e, index, idx, row.stock)
                                }
                                data-testid="phy_stock"
                                value={row.physical_stock}
                                width="60%"
                                name="physical_stock"
                                type="number"
                                min="0"
                                submitted={
                                  (parseInt(row.physical_stock) <=
                                    parseInt(row.stock) &&
                                    row.adjustment_type === "I") ||
                                  (submitted && !row.physical_stock) ||
                                  (parseInt(row.physical_stock) >=
                                    parseInt(row.stock) &&
                                    row.adjustment_type !== "" &&
                                    row.adjustment_type === "D")
                                }
                              />

                              {submitted && !row.physical_stock ? (
                                <div className="invalid-text">
                                  Physical stock is required
                                </div>
                              ) : null}

                              {parseInt(row.physical_stock) >=
                                parseInt(row.stock) &&
                              row.adjustment_type !== "" &&
                              row.adjustment_type === "D" ? (
                                <div className="invalid-text">
                                  Physical stock should be less than actual
                                  stock
                                </div>
                              ) : null}

                              {parseInt(row.physical_stock) <=
                                parseInt(row.stock) &&
                              row.adjustment_type === "I" ? (
                                <div className="invalid-text">
                                  Physical stock should be more than actual
                                  stock
                                </div>
                              ) : null}
                            </DataCell>
                            {/* {console.log(row.adjustment_type)} */}
                            <DataCell align="center">
                              <StyledSelect
                                width="80%"
                                defaultOption="Select...."
                                data-testid="adjustment_type"
                                options={[
                                  { value: "I", text: "Increment" },
                                  { value: "D", text: "Decrement" },
                                ]}
                                value={row.adjustment_type}
                                name="adjustment_type"
                                onChange={(e) =>
                                  handleChangeItem(e, index, idx)
                                }
                              />
                              {submitted && !row.adjustment_type ? (
                                <div className="invalid-text">
                                  Adjustment type is required
                                </div>
                              ) : null}
                            </DataCell>
                            <DataCell align="center">
                              <Textarea
                                value={row.reason}
                                name="reason"
                                data-testid="reason"
                                onChange={(e) =>
                                  handleChangeItem(e, index, idx)
                                }
                              />
                            </DataCell>
                            <DataCell align="center">
                              {row.isBatch ? (
                                row.unit_cost
                              ) : (
                                <>
                                  <StyledInput
                                    submitted={submitted}
                                    data-testid="unit_cost"
                                    onChange={(e) =>
                                      handleChangeItem(e, index, idx)
                                    }
                                    value={row.unit_cost}
                                    name="unit_cost"
                                    type="number"
                                    width="40%"
                                  />
                                  {submitted && !row.unit_cost && (
                                    <div className="invalid-text">
                                      Unit cost is required
                                    </div>
                                  )}
                                </>
                              )}
                            </DataCell>
                            <DataCell align="right">
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => handleRemoveBatch(index, idx)}
                                >
                                  <StyledRemoveIcon />
                                </IconButton>
                              </Tooltip>
                            </DataCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell colSpan={headData.length}>
                          <button
                            type="button"
                            onClick={() => {
                              console.log("product id", item.product_id);
                              handleAddBatch(index, item.product_id);
                            }}
                            className="btn btn-sm pharmacy-grey-btn"
                          >
                            Add another row
                          </button>
                        </TableCell>
                        *
                      </TableRow>
                    </ModuleTable>
                  </CollapsibleList>
                ) : null;
              })
            ) : (
              <div>
                <p className="text-center mt-2">
                  There are no batches for this product, please purchase product
                  first
                </p>

                <button
                  type="button"
                  onClick={() => {
                    products.map((item, index) =>
                      handleAddBatch(index, item.product_id)
                    );
                  }}
                  className="btn btn-sm pharmacy-grey-btn"
                >
                  Add Batch
                </button>
              </div>
            )
          ) : null}
          {isError ? (
            <div className="text-center">
              The server did not return a valid response
            </div>
          ) : null}
          {productLength & !isResolved ? (
            <div className="text-right">
              <button
                type="submit"
                disabled={isPending}
                // data-testid="submit"
                data-testid="submit_button"
                className="btn btn-sm pharmacy-btn"
                onClick={handleSubmit}
              >
                {isPending ? "Submitting...." : "Submit"}
              </button>
            </div>
          ) : null}
        </form>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StockAdjustment);
